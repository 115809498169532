<template>
  <section class="addAccount panel-section">
    <div class="back-btn" @click="back">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
    <div class="title">新增客户账户</div>
    <el-form
      :model="formModel"
      label-position="left"
      status-icon
      :rules="rules"
      ref="formModel"
      label-width="100px"
      class="demo-formModel"
    >
      <el-form-item label="选择客户" prop="shopAppId">
        <el-select
          v-model="formModel.shopAppId"
          placeholder="请选择客户"
          @change="changeAccount"
        >
          <el-option
            v-for="item in accountList"
            :key="item.id"
            :label="`${item.id}-${item.name}`"
            :value="`${item.id}-${item.appId}`"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="渠道编号" prop="appId">
        <el-input
          type="text"
          placeholder="选择客户后自动填入"
          v-model="formModel.appId"
          :readonly="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="账户方案" prop="chargingModel">
        <el-select
          v-model="formModel.chargingModel"
          placeholder="请选择账户方案"
          @change="changeModel"
        >
          <el-option
            v-for="index in Object.keys(schemeType)"
            :key="index"
            :label="schemeType[index]"
            :value="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="formModel.chargingModel == 3"
        label="方案规格"
        prop="billingScheme"
      >
        <el-select
          v-model="formModel.billingScheme"
          placeholder="请选择方案规格"
        >
          <el-option
            v-for="index in Object.keys(schemeSpec)"
            :key="index"
            :label="schemeSpec[index]"
            :value="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="方案有效期" prop="expireDate">
        <el-select
          v-model="formModel.expireDate"
          placeholder="请选择方案有效期"
        >
          <el-option
            v-for="index in Object.keys(schemeExpire)"
            :key="index"
            :label="schemeExpire[index]"
            :value="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formModel')"
          >新增</el-button
        >
        <el-button @click="resetForm('formModel')">重置</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
//客户管理--新增客户
import API_ACCOUNT from "@/api/api_account";
import { ACCOUNT_SCHEMA } from "@/util/constant";

export default {
  name: "AddAccount",
  components: {},
  data() {
    return {
      formModel: {
        shopId: undefined,
        appId: undefined,
        chargingModel: undefined,
        billingScheme: "0",
        expireDate: "0",
        shopAppId: undefined,
      },
      rules: {
        shopAppId: { required: true, message: "请选择" },
        appId: { required: true, message: "请先选择客户" },
        chargingModel: { required: true, message: "请选择账户方案" },
        billingScheme: { required: true, message: "请选择方案规格" },
        expireDate: { required: true, message: "请选择方案有效期" },
      },
      accountList: [],
    };
  },
  computed: {
    schemeType() {
      return ACCOUNT_SCHEMA["type"];
    },
    schemeSpec() {
      return ACCOUNT_SCHEMA["spec"][this.formModel.chargingModel];
    },
    schemeExpire() {
      return this.formModel.chargingModel == 3
        ? {
            0: "不限",
          }
        : ACCOUNT_SCHEMA["expire"];
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    resetForm(name) {
      this.$refs[name].resetFields();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(`确定新增客户账户吗？`, "提示", {
            type: "warning",
          }).then(() => {
            const params = {
              chargingModel: Number(this.formModel.chargingModel),
              expireDate: Number(this.formModel.expireDate),
              shopId: this.formModel.shopId,
            };
            if (this.formModel.chargingModel == 3) {
              params.billingScheme = Number(this.formModel.billingScheme);
            }
            API_ACCOUNT.setAccountCustom(params, true).then(({ data }) => {
              if (data.code == 200) {
                this.$message({
                  message: "新增成功~",
                  type: "success",
                });
                this.back();
              } else {
                this.$message.error({
                  message: data.msg || this.$CommonMsg["networkErr"],
                });
              }
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getAccountList() {
      API_ACCOUNT.getAccountFill({
        pageNo: 1,
        pageSize: 200,
        openCharging: true,
        // auditStatus: 2
      }).then(({ data }) => {
        if (data.code == 200 && data.data) {
          this.accountList = data.data.records || [];
        } else {
          this.accountList = [];
        }
      });
    },
    changeAccount(data) {
      this.formModel.shopId = data.split("-")[0];
      this.formModel.appId = data.split("-")[1];
    },
    changeModel(data) {
      console.log(this.formModel);
      if(this.formModel.chargingModel == 3) {
        this.formModel.expireDate = "0";
      }
    }
  },
  activated() {
    this.getAccountList();
  },
  deactivated() {
    this.formModel = {
      shopId: undefined,
      appId: undefined,
      chargingModel: undefined,
      billingScheme: "0",
      expireDate: undefined,
      shopAppId: undefined,
    };
    this.resetForm("formModel");
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_basePage.scss";

.addAccount {
  padding: 40px 48px;
  .title {
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303133;
    margin: 24px 0;
  }
}
/deep/ .el-form-item__content {
  width: 430px;
}
</style>
